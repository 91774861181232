const addScript = (url: string) => {
  const script = document.createElement("script")
  script.src = url
  script.async = false
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addScript('/plugins/jquery/jquery.min.js');
    addScript('/plugins/bootstrap/bootstrap.bundle.min.js');
    addScript('/plugins/swiper/swiper-bundle.min.js');
    addScript('/plugins/isotope/isotope.min.js');
    setTimeout(() => {
      addScript('/assets/js/script.js');
      console.log('load script')
    }, 200)
  }
}