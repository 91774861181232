import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { onClientEntry } from '../theme/theme';
import { Footer } from './nav/Footer';
import { FooterNote } from './nav/FooterNote';
import { GoToTop } from './nav/GoToTop';
import { Header } from './nav/Header';

import "../../static/assets/css/style.css";
import "../../static/assets/css/custom.css";

const StyledBackgroundImage = () => (
  <div style={{
    backgroundImage: "url('/assets/images/background-image.jpg')",
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    position: 'fixed',
    opacity: '2%',
  }} />
)
export const Layout: React.FC = ({ children }) => {

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    window.location.assign("https://instagram.com/0ximagineaction");
    onClientEntry();
    setTimeout(() => {
      setMounted(true);
    }, 1000)
  }, []);

  return (
    <>
      <Helmet
        defer={false}>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        {/* Fav icon */}
        <link rel="icon" type="image/png" href="/assets/images/favicon.png" />
        {/* Plugins */}
        <link rel="stylesheet" href="/plugins/bootstrap/bootstrap.min.css" />
        <link rel="stylesheet" href="/plugins/line-awesome/css/line-awesome.min.css" />
        <link rel="stylesheet" href="/plugins/swiper/swiper-bundle.min.css" />
        {/* Style */}
        <link rel="stylesheet" href="/assets/css/style.css" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
      </Helmet>
      <StyledBackgroundImage />
      <Header />
      <GoToTop />
      {mounted && (
        children
      )}
      <Footer />
      <FooterNote />
    </>
  )
}