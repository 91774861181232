import { Link } from 'gatsby';
import React from 'react';
import { CONTACT_EMAIL } from '../../data/Email';
import { IADiscord, IAInstagram, IATwitter } from '../../data/Socials';

export const Footer = () => (
  // has-anim
  <footer className="section-sm pb-4">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-title">
            <h4 className="h3 font-weight-light text-white">Have an idea, tell us about it!<a href="contact.html"
              className="text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" strokeWidth="2">
                <line x1="7" y1="17" x2="17" y2="7"></line>
                <polyline points="7 7 17 7 17 17"></polyline>
              </svg></a></h4>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 mb-4 mb-lg-0">
          <h4 className="font-weight-light mb-4"><a href={`mailto:${CONTACT_EMAIL}`}
            className="line-link">{CONTACT_EMAIL}</a></h4>
          {/* <p>Phone: +072 223 2424</p>
          <p>75 E Santa Clara St, Ste 1425</p>
          <p>Alexandria, VA 22314, USA</p> */}
        </div>
        <div className="col-lg-2 col-md-2 col-sm-4 col-6 ml-auto">
          <ul className="footer-link list-unstyled">
            <li><Link to="/">Home</Link></li>
            <li><Link to="#faq">FAQ</Link></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-4 col-6">
          <ul className="footer-link list-unstyled">
            <li><Link to="#team">Team</Link></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
          <ul className="footer-link list-unstyled">
            <li><Link to="#roadmap">Roadmap</Link></li>
          </ul>
        </div>
        <div className="col-12">
          <ul className="footer-link list-unstyled list-inline mt-4 mt-md-5">
            <li className="list-inline-item">
              <a href={IATwitter} target="_blank">
                Twitter
              </a>
            </li>
            <li className="list-inline-item">
              <a href={IAInstagram} target="_blank">
                Instagram
              </a>
            </li>
            <li className="list-inline-item">
              <a href={IADiscord} target="_blank">
                Discord
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)