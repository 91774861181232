type Link = {
  title: string;
  to: string;
}

export const LINKS: Link[] = [{
  title: 'Home',
  to: '/'
}, {
  title: 'Roadmap',
  to: '#roadmap'
}, {
  title: 'Team',
  to: '#team'
}, {
  title: 'FAQ',
  to: '#faq'
}];
