import { Link } from 'gatsby';
import React from 'react';
import { LINKS } from '../../data/Links';
import { IADiscord, IATwitter } from '../../data/Socials';

const HamburgerMenu = () => (
  <button className="navbar-toggler" type="button" data-toggle="navbar-menu">
    <svg viewBox="0 0 341.333 341.333">
      <g>
        <g>
          <rect y="277.333" width="341.333" height="42.667" />
        </g>
      </g>
      <g>
        <g>
          <rect y="149.333" width="341.333" height="42.667" />
        </g>
      </g>
      <g>
        <g>
          <rect y="21.333" width="341.333" height="42.667" />
        </g>
      </g>
    </svg>
  </button>
)

export const Header = () => {
  return (
    <>
      <div className="adjust-navigation-height" />
      <header className="navigation">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg navbar-dark px-0 py-3 py-lg-4">
                {/* <!-- navbar logo --> */}
                <Link className="navbar-brand font-weight-bold mb-0" to="/" title="Imagine Action">
                  <img src="assets/images/logo.svg" alt="Imagine Action logo" />
                </Link>

                {/* <!-- navbar-toggler --> */}
                {/* <HamburgerMenu /> */}

                {/* <!-- sidenav-overlay --> */}
                <div className="sidenav-overlay"></div>

                {/* <!-- navbar links --> */}
                <div className="navbar-collapse" id="navbar-menu">
                  <ul className="navbar-nav mx-auto">
                    <li data-toggle="navbar-menu-close"><i className="las la-times mr-1"></i>Close</li>
                    {LINKS.map(item => (
                      <li className="nav-item">
                        <Link className="nav-link" to={item.to}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {/* <!-- navbar actions --> */}
                  <div className="navbar-actions">
                    <a
                      href={IADiscord}
                      className="btn btn-white"
                      data-text="Join"
                      target="_blank"
                      rel="nofollow">
                      <span>Discord</span>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}